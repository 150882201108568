  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    

    
    

}



body{
    font-family: 'Source Sans 3', sans-serif;
    background-color: #ffff;


}

.container{
    max-width: 1170px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    overflow-x: hidden;

}
.bgc{
    background-color: #ffff;
    min-width: auto;

}

section{
    padding: 50px 0;
}

@media only screen and (max-width: 768px) {
    .menu{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #00000084;
        z-index: 100;
        display: none;
    }
    .menu ul{
        width: 15rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        background: #ffff;
        flex-direction: column;
        line-height: 30px;
        justify-content: center;
        align-items: center;

    }
    .show_menu{
        display: block;

    }
    
}
.navi.active{
   
    text-decoration: underline 2px;
    color: #9D0208;
  }
.load{
    animation: fade-in-out 2s linear infinite alternate;
}
@keyframes fade-in-out{
    to{
        opacity: 0;
    }
}